import { Button, Image, makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';

import { useChat } from '../../libs/hooks';

import airlie300 from '../../assets/bot-icons/Airlie300X300.png';

const useClasses = makeStyles({
    content: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: '1.5rem',
    },
    title: {
        ...shorthands.gap('16px'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerContent: {
        display: 'flex',
        columnGap: '1rem',
    },
});

const Landing: React.FC = () => {
    const classes = useClasses();
    const chat = useChat();

    const onAddChat = () => {
        void chat.createChat();
    };

    return (
        <div className={classes.content}>
            <div className={classes.title}>
                <Image alt="Airlie" src={airlie300} height={80} width={80} />
                {/* <Subtitle2 as="h3">Hi! I&apos;m Airlie, I&apos;m powered by AI and here to help..</Subtitle2>
                <Title2 as="h1">Ask me anything...</Title2> */}
            </div>
            <div className={classes.innerContent}>
                <Button size="large" appearance="primary" onClick={onAddChat}>
                    Start a Chat
                </Button>
            </div>
        </div>
    );
};

export default Landing;
