import { BaseService } from './BaseService';

export interface SharePointSettings {
    listId: string;
    siteId: string;
    sitePath: string;
}

export class SharePointSettingsService extends BaseService {
    public getStorageSettings = async (accessToken: string) => {
        const result = await this.getResponseAsync<SharePointSettings>(
            {
                commandPath: 'SharePointStorageSettings',
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };
}
