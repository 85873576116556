import { Card, makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';

const useClasses = makeStyles({
  root: {
    ...shorthands.overflow('hidden'),
    ...shorthands.padding('30px'),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%'
  },
  contentGroup: {
    ...shorthands.padding('10px'),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'fit-contnet'
  },
  suggstionList: {
    overflowY: 'scroll'
  }
});

const useStyles = makeStyles({

  card: {
    width: "200px",
    height: "fit-content",
    marginBottom: '10px',
    overflowWrap: 'break-word'
  },
  text: {
    ...shorthands.margin(0),
  },
});

interface ChatSuggestionProps {
  onSuggestionClick: (t: string) => void;
}

export const ChatSuggestion: React.FC<ChatSuggestionProps> = ({ onSuggestionClick }) => {
  const classes = useClasses();
  const styles = useStyles();

  return (
    <div className={classes.root} >
      <div className={classes.contentGroup}>
        <h2>Examples</h2>
        <div className={classes.suggstionList}>
          <Card
            className={styles.card}
            onClick={() => { onSuggestionClick("Draft an email explaining [scenario]") }}
          >
            <p className={styles.text}>
              Draft an email explaining [scenario]
            </p>
          </Card>
          <Card
            className={styles.card}
            onClick={() => { onSuggestionClick("Provide detailed historical context or background of [event]") }}
          >
            <p className={styles.text}>
              Provide detailed historical context or background of [event]
            </p>
          </Card>
          <Card
            className={styles.card}
            onClick={() => { onSuggestionClick("Offer detailed guidance or instructions on performing [task]") }}
          >
            <p className={styles.text}>
              Offer detailed guidance or instructions on performing [task]
            </p>
          </Card>
        </div>
      </div>
      <div className={classes.contentGroup}>
        <h2>Capabilities</h2>
        <div className={classes.suggstionList}>
          <Card
            className={styles.card}
          >
            <p className={styles.text}>
              Remembers what you said earlier in the conversation
            </p>
          </Card>
          <Card
            className={styles.card}
          >
            <p className={styles.text}>
              Allows you to provide follow-up corrections
            </p>
          </Card>
          <Card
            className={styles.card}
          >
            <p className={styles.text}>
              Trained to decline inappropriate requests
            </p>
          </Card>
        </div>
      </div>
      <div className={classes.contentGroup}>
        <h2>Limitations</h2>
        <div className={classes.suggstionList}>
          <Card
            className={styles.card}
          >
            <p className={styles.text}>
              May occasionally generate incorrect information
            </p>
          </Card>
          <Card
            className={styles.card}
          >
            <p className={styles.text}>
              May occasionally produce harmful instructions or biased content
            </p>
          </Card>
          <Card
            className={styles.card}
          >
            <p className={styles.text}>
              Limited knowledge of world and events after 2021
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
};