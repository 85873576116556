import { Button } from '@fluentui/react-components';

export const RulesOfUse = () => {
  const handleWhatsNewClick = () => {
    window.open('https://allens.sharepoint.com/sites/ChatGPTandGenerativeAI/SitePages/FAQs.aspx#what-do-i-need-to-think-about-when-using-it-%E2%80%93-what-are-the-raapca-rules', '_blank');
  };

  return (
    <Button appearance="transparent" style={{ color: 'white' }} onClick={handleWhatsNewClick}>
      Rules of use
    </Button>
  );
};