import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import { AuthHelper } from '../auth/AuthHelper';
import { SharePointListService } from '../services/SharePointListService';
import { SharePointSettingsService } from '../services/SharePointSettingsService';

export interface SharePointList {
    title: string;
    prompt: string;
}

interface SharePointListError {
    error: {
        code: string;
        message: string;
    };
}

export const useSharePoint = () => {
    const { instance, inProgress } = useMsal();

    const [list, setList] = useState<SharePointList[]>([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const getSharePointData = async () => {
            const sharePointSettingsService = new SharePointSettingsService();
            const sharepointListService = new SharePointListService();
            const graphAccessToken = await AuthHelper.getGraphAccessToken(instance, inProgress);
            const skAccessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

            const settings = await sharePointSettingsService.getStorageSettings(skAccessToken);
            const response = await sharepointListService.getLists(graphAccessToken, settings);

            const sharePointList: SharePointList[] = response.value.map(({ fields }) => ({
                title: fields.Title,
                prompt: fields.Prompt,
            }));
            setList(sharePointList);
        };

        getSharePointData().catch((err) => {
            const error = err as Error;
            const [, errorCode, errDetails] = error.message.split(/Error:\s(\d+):\s({.*})/);
            const errorData = JSON.parse(errDetails) as SharePointListError;

            if (parseInt(errorCode) === 403 && errorData.error.code === 'accessDenied') {
                setError(`You don't currently have access to shared prompts.`);
            }
        });
    }, [instance, inProgress]);

    return {
        sharePointList: list,
        error,
    };
};
