import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { FC } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';

import Landing from './Landing';
import { Loading } from './Loading';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground3,
        boxShadow: 'rgb(0 0 0 / 25%) 0 0.2rem 0.4rem -0.075rem',
    },
});

export const ChatView: FC = () => {
    const classes = useClasses();
    const { selectedId, status } = useAppSelector((state: RootState) => state.conversations);

    const content = () => {
        if (status === 'pending') {
            return (
                <div className={classes.loading}>
                    <Loading text={'Hang tight while we fetch your chat...'} />
                </div>
            );
        }

        if (selectedId !== '') {
            return <ChatWindow />;
        } else {
            return <Landing />;
        }
    };

    return (
        <div className={classes.container}>
            <ChatList />
            {content()}
        </div>
    );
};
