import { Button } from '@fluentui/react-components';

export const WhatsNew = () => {
  const handleWhatsNewClick = () => {
    window.open('https://allens.sharepoint.com/:u:/r/sites/ChatGPTandGenerativeAI/SitePages/New-feature-log.aspx', '_blank');
  };

  return (
    <Button appearance="transparent" style={{ color: 'white' }} onClick={handleWhatsNewClick}>
      What&apos;s new!
    </Button>
  );
};