import {
    Button,
    Menu,
    MenuItemLink,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from "@fluentui/react-components";

export const HelpMenu = () => (
    <Menu>
        <MenuTrigger disableButtonEnhancement>
            <Button
                // icon={<QuestionCircleRegular
                //     fontSize={80}
                //     primaryFill="#FFFFFF"
                // />}
                appearance="transparent"
                style={{ color: 'white' }}
            >Help</Button>
        </MenuTrigger>

        <MenuPopover>
            <MenuList>
                <MenuItemLink href="https://allens.sharepoint.com/sites/ChatGPTandGenerativeAI/SitePages/FAQs.aspx"
                    target="_window">
                    FAQ
                </MenuItemLink>
                <MenuItemLink href="https://forms.office.com/r/GUAWx1S90Q"
                    target="_window">
                    Feedback
                </MenuItemLink>
                {/* <MenuItem disabled>Open File</MenuItem> */}
            </MenuList>
        </MenuPopover>
    </Menu>
);