import { Card, makeStyles, shorthands } from '@fluentui/react-components';
import React, { ReactNode } from 'react';

import { SharePointList, useSharePoint } from '../../../libs/hooks/useSharePoint';
import { AlertType } from '../../../libs/models/AlertType';
import { useAppDispatch } from '../../../redux/app/hooks';
import { addAlert } from '../../../redux/features/app/appSlice';

const useClasses = makeStyles({
    root: {
        ...shorthands.overflow('hidden'),
        ...shorthands.padding('30px'),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '100%',
    },
    contentGroup: {
        ...shorthands.padding('10px'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 'fit-contnet',
    },
    suggstionList: {
        overflowY: 'scroll',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        columnGap: '20px',
        rowGap: '10px',
        maxWidth: '800px',
        width: '100%',
    },
    card: {
        width: '200px',
        height: 'fit-content',
        marginBottom: '10px',
        overflowWrap: 'break-word',
    },
    text: {
        ...shorthands.margin(0),
        textOverflow: 'ellipsis',
        maxHeight: '40px',
        overflowX: 'hidden',
        overflowY: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        display: '-webkit-box',
    },
});

interface Props {
    onClickPrompt: (value: SharePointList) => void;
}

export const PromptsTab: React.FC<Props> = ({ onClickPrompt }) => {
    const { sharePointList, error } = useSharePoint();
    const dispatch = useAppDispatch();

    const classes = useClasses();

    React.useEffect(() => {
        if (error !== '') {
            dispatch(addAlert({ message: error, type: AlertType.Error }));
        }
    }, [dispatch, error]);

    return (
        <div className={classes.root}>
            <div className={classes.contentGroup}>
                <h2>Suggested Prompts</h2>
                <div className={classes.suggstionList}>
                    {sharePointList.map<ReactNode>(({ title, prompt }, idx) => (
                        <Card
                            className={classes.card}
                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                            key={title + prompt + idx}
                            onClick={() => {
                                onClickPrompt({ title, prompt });
                            }}
                        >
                            <h4 className={classes.text}>{title}</h4>
                            <p className={classes.text}>{prompt}</p>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};
