import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { Breakpoints } from '../../../styles';
import { ChatListItem } from './ChatListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalXXS),
        paddingBottom: tokens.spacingVerticalXS,
    },
    header: {
        marginTop: 0,
        paddingBottom: tokens.spacingVerticalXS,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground3,
        ...Breakpoints.small({
            display: 'none',
        }),
    },
});

interface IChatListSectionProps {
    header?: string;
    conversations?: Conversations;
}

export const ChatListSection: React.FC<IChatListSectionProps> = ({ header }) => {
    const classes = useClasses();
    const { selectedId, chatSessions } = useAppSelector((state: RootState) => state.conversations);

    return chatSessions.length > 0 ? (
        <div className={classes.root}>
            <Text className={classes.header}>{header}</Text>
            {chatSessions.map((session) => {
                const isSelected = session.id === selectedId;
                return <ChatListItem id={session.id} key={session.id} isSelected={isSelected} header={session.title} />;
            })}
        </div>
    ) : null;
};
